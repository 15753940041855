* {
  box-sizing: border-box;
}
@tailwind base;
@tailwind components;
@tailwind utilities;
html, body {
  height: 100%
}
body {
  @apply bg-brand-500;
}
.screenHeight {
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
}
.pageBg {
  background: url('./assets/images/bgpattern.jpg') center repeat;
  max-width: 420px;
  margin: 0 auto;
}
.form-group {
  @apply mb-3 relative
}
.form-control {
  @apply focus:ring-2 focus:ring-brand-500 focus:outline-none appearance-none w-full leading-6 text-slate-900 placeholder-slate-400 rounded-md py-2 px-3 ring-1 ring-slate-200 shadow-sm;
}
.form-label {
  @apply text-sm leading-6 font-medium text-slate-600 group-focus-within:text-[#1992bc] mb-1 inline-block;
}
.btn {
  @apply bg-gradient-to-t from-brand-600 to-brand-400 shadow text-white text-sm leading-6 font-medium py-2 px-3 rounded-lg ;
}
.swiper-button-prev.swiperBtn,
.swiper-button-next.swiperBtn {
  @apply bg-gradient-to-t from-brand-600 to-brand-400 text-white rounded text-sm h-auto px-4 py-2 w-auto shadow-md;
}
.swiper-button-prev.prev:after,
.swiper-button-next.next:after {
  display: none;
}
.customBg {
  /* background: url('./assets/promotional/pattern.png') repeat-y; */
  background: #c6edf7;
  /* background-size: contain; */
}
.customVideo video{
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  transform: scaleX(-1);
}